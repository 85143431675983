import {useLogger, useRequestHeaders, useRequestURL} from '#imports';

// Copied and adapted from amplitude-tracking-create-account-started-signup.ts
// The `pageSource` query parameter is then consumed by /signup-client,
// see signup-client-front:useSignupTracking
export function useSignupAttributesPropagation() {
    const logger = useLogger();

    function determinePageSource() {
        const requestUrl = useRequestURL();
        const explicitPageSource = requestUrl.searchParams.get('pageSource');
        if (explicitPageSource) {
            return explicitPageSource;
        }

        const requestHeaders = import.meta.server ? useRequestHeaders() : {referer: document.referrer};
        const referrer = requestHeaders.referer || null;
        if (!referrer) {
            return null;
        }

        const referrerUrl = new URL(referrer);

        // /!\ We should explicitly add the `pageSource` parameter to the /signup (temporarily /who-are-you)
        // link when possible.

        if (referrerUrl.pathname === '/project/new') {
            return 'search_brief_submission';
        }

        if (referrerUrl.pathname.match(/customer\/searches\/(?:exec\/)?draft\/.*\/overview/)) {
            return 'brief_submission';
        }

        if (referrerUrl.pathname === '/' || referrerUrl.pathname === '/home') {
            return 'home';
        }

        if (referrerUrl.pathname.startsWith('/profile/')) {
            return 'profile_page';
        }

        if (referrerUrl.pathname.startsWith('/c/') || referrerUrl.pathname.startsWith('/a/') || referrerUrl.pathname.startsWith('/s/tags/')) {
            return 'cms_marketing_page';
        }

        if (referrerUrl.pathname.startsWith('/ressources/')) {
            return 'ressource_center';
        }

        if (referrerUrl.pathname.startsWith('/proposal/')) {
            return 'proposal_page';
        }

        // Click on 'Create my account' during a search
        if (referrerUrl.pathname === '/s') {
            return 'search';
        }

        return null;
    }

    function determineComponentSource() {
        const requestUrl = useRequestURL();
        const explicitComponentSource = requestUrl.searchParams.get('componentSource');

        if (explicitComponentSource) {
            // componentSource is defined for pageSource=profile_page
            const validComponentSources = [
                'profile-appraisals-section',
                'profile-certifications-section',
                'profile-description-section',
                'profile-education-section',
                'profile-experience-item-description',
                'profile-experiences-section',
                'profile-industry-expertise-section',
                'profile-languages-section',
                'profile-recommendations-section',
                'profile-similar-profiles-section',
                'profile-skill-set-section',
                'profile-skills-section',
                'profile-view-blocker',
            ];

            if (!validComponentSources.includes(explicitComponentSource)) {
                if (import.meta.dev) {
                    logger.error(`Invalid component source: ${explicitComponentSource}`);
                }
                return 'invalid';
            }
        }

        return explicitComponentSource ?? null;
    }

    function determineRedirect() {
        const requestUrl = useRequestURL();
        return requestUrl.searchParams.get('redirect');
    }

    function determineClientSignupParameters(): Record<string, string> {
        const params: Record<string, string> = {};

        const pageSource = determinePageSource();
        if (pageSource) {
            params.pageSource = pageSource;
        }

        const componentSource = determineComponentSource();
        if (componentSource) {
            params.componentSource = componentSource;
        }

        const redirect = determineRedirect();
        if (redirect) {
            params.redirect = redirect;
        }

        return params;
    }

    function toQueryString(params: Record<string, string>) {
        const queryString = new URLSearchParams(params).toString();
        return queryString ? `?${queryString}` : '';
    }

    function addPageSourceParamToUrl(url: string) {
        const pageSource = determinePageSource();
        if (pageSource) {
            return `${url}${url.includes('?') ? '&' : '?'}pageSource=${encodeURIComponent(pageSource)}`;
        }
        return url;
    }

    return {
        addPageSourceParamToUrl,
        determineClientSignupParameters,
        toQueryString,
    };
}
